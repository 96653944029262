import { memo } from 'react';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';

import { useIsMobile } from '@contexts/mobile-detector-provider';
import { sizesMap } from '@lib/constants/sizes-map';
import { countriesMap, ICountriesMap } from '@lib/countriesMap';
import { IAttribute, IProductAttributesIcons, SizesMapType } from '@types';
import { Icon } from '../../shared';

import classes from './InfoSection.module.scss';

interface IInfoSectionProps {
  name?: string;
  attributes: IAttribute[];
  country?: ICountriesMap;
}

type IInfoSection = (props: IInfoSectionProps) => JSX.Element;

const Info: IInfoSection = ({ name, attributes, country }) => {
  const firstAttr = attributes[0];
  const secondAttr = attributes[1];
  const thirdAttr = attributes[2];
  const { isMobile } = useIsMobile();

  return (
    <>
      <p className={classes.title}>{name}</p>
      {isMobile ? (
        <div className={classes.attributes}>
          <div className={classes.mobileAttributesList}>
            {firstAttr && firstAttr.name ? (
              <>
                <span className={classes.mobileAttribute}>
                  {firstAttr.alias ? (
                    <Icon
                      className={classes.attributeIcon}
                      type={firstAttr.alias as IProductAttributesIcons}
                      size={16}
                    />
                  ) : null}
                  &nbsp;{sizesMap[String(firstAttr?.value) as keyof SizesMapType] || firstAttr?.value || '-'}
                  &nbsp;
                  {firstAttr.format || ''}
                </span>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </>
            ) : null}
            {secondAttr && secondAttr.name ? (
              <>
                <span className={classes.mobileAttribute}>
                  {secondAttr.alias ? (
                    <Icon
                      className={classes.attributeIcon}
                      type={secondAttr.alias as IProductAttributesIcons}
                      size={16}
                    />
                  ) : null}
                  &nbsp;{sizesMap[String(secondAttr?.value) as keyof SizesMapType] || secondAttr.value || '-'}&nbsp;
                  {secondAttr.format || ''}
                </span>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </>
            ) : null}
          </div>
          <div className={classes.mobileAttributesList}>
            {thirdAttr && thirdAttr.name ? (
              <>
                <span className={classes.mobileAttribute}>
                  {thirdAttr.alias ? (
                    <Icon
                      className={classes.attributeIcon}
                      type={thirdAttr.alias as IProductAttributesIcons}
                      size={16}
                    />
                  ) : null}
                  &nbsp;{sizesMap[String(thirdAttr?.value) as keyof SizesMapType] || thirdAttr.value || '-'}&nbsp;
                  {thirdAttr.format || ''}
                </span>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </>
            ) : null}
            {country ? (
              <FlagIcon code={country.toUpperCase() as FlagIconCode} size={12} style={{ marginRight: 4 }} />
            ) : null}
            {country ? <span>{countriesMap[country] ? countriesMap[country].name : country}</span> : null}
          </div>
        </div>
      ) : (
        <div className={classes.attributes}>
          <div className={classes.attributesList}>
            {firstAttr && firstAttr.name ? (
              <>
                <span className={classes.attribute}>
                  {`${firstAttr.name}: ${
                    sizesMap[String(firstAttr?.value) as keyof SizesMapType] || firstAttr.value || ''
                  } ${firstAttr.format || ''}`}
                </span>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </>
            ) : null}
            {secondAttr && secondAttr.name ? (
              <>
                <span className={classes.attribute}>
                  {`${secondAttr.name}: ${
                    sizesMap[String(secondAttr?.value) as keyof SizesMapType] || secondAttr.value || ''
                  } ${secondAttr.format || ''}`}
                </span>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </>
            ) : null}
          </div>
          <div className={classes.attributesList}>
            {thirdAttr && thirdAttr.name ? (
              <>
                <span className={classes.attribute}>
                  {`${thirdAttr.name}: ${
                    sizesMap[String(thirdAttr?.value) as keyof SizesMapType] || thirdAttr.value || ''
                  } ${thirdAttr.format || ''}`}
                </span>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </>
            ) : null}
            {country ? (
              <FlagIcon code={country.toUpperCase() as FlagIconCode} size={12} style={{ marginRight: 4 }} />
            ) : null}
            {country ? <span>{countriesMap[country] ? countriesMap[country].name : country}</span> : null}
          </div>
        </div>
      )}
    </>
  );
};

function areEqual(prevProps: IInfoSectionProps, nextProps: IInfoSectionProps) {
  return (
    prevProps.name === nextProps.name &&
    prevProps.country === nextProps.country &&
    JSON.stringify(prevProps.attributes) === JSON.stringify(nextProps.attributes)
  );
}

const InfoSectionMemo = memo(Info, areEqual);
export { InfoSectionMemo as InfoSection };
